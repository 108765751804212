import classNames from 'classnames';
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { ASSEMBLER_HELP, MARCAPS_SUPPORT } from 'const';
import { ExtendedAssemblerError, NotificationWindowOptions } from 'models';
import { intlGet } from 'utils/intlGet';
import { NotificationProps } from '../Notification/models';
import CopyErrorIcon from './CopyClick';
import styles from './styles.module.scss';

const NotificationAccessError: React.FunctionComponent<NotificationProps> = (props) => {
  const { options, modalWindowId, hideModal } = props;
  const {
    title,
    message,
    error,
  } = options.toJS() as NotificationWindowOptions & { error: ExtendedAssemblerError };

  const onCloseClick = (): void => {
    hideModal(modalWindowId);
  };

  return (
    <div className={styles.NotificationAccessError}>
      <div className={classNames(styles.title, 'text-center')}>{title}</div>

      <div className='mt-4'>{message}</div>
      <div className='mt-4'>
        {intlGet('Notification.PromoMatsError.AccessError', 'DocumentIdMessage')}:<br/>
        <ol>
          <li><a target='_blank' href={error.documentInVeevaUrl}>{error.documentId}</a></li>
        </ol>
      </div>

      <div className='mt-4'>
      Recommendations:
        <ul>
          <li>try contacting the document owner and request access to the document</li>
          <li>
              visit the <a target='_blank' href={ASSEMBLER_HELP}>Content Capabilities Help Site</a>.
              To get the credentials to access the site send an email to <a href='mailto:modularcontent@merck.com'>modularcontent@merck.com</a>
          </li>
          <li>report <a target='_blank' href={MARCAPS_SUPPORT}>MARCAPS ticket</a></li>
        </ul>
      </div>

      <div className='d-flex mt-4' style={{ height: 50 }}>
        <CopyErrorIcon asmolError={error} />
      </div>

      <div className='text-center mt-4'>
        <Button className={styles.button} onClick={onCloseClick}>{intlGet('NotificationWindow.Button', 'Close')}</Button>
      </div>
    </div>
  );
};

export default NotificationAccessError;
