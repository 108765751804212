import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalType } from 'const';
import AddImage from 'containers/ModalWindows/AddImageWindow';
import AddReferenceCitation from 'containers/ModalWindows/AddReferenceCitation';
import AddScreen from 'containers/ModalWindows/AddScreen';
import ChooseScreenFormat from 'containers/ModalWindows/ChooseScreenFormat';
import ColorGradientPicker from 'containers/ModalWindows/ColorGradientPicker';
import Confirmation from 'containers/ModalWindows/Confirmation';
import DocumentDuplicates from 'containers/ModalWindows/DocumentDuplicates';
import DocumentSaving from 'containers/ModalWindows/DocumentSaving';
import EditReusableLayout from 'containers/ModalWindows/EditReusableLayout';
import Export from 'containers/ModalWindows/Export';
import ImageDetails from 'containers/ModalWindows/ImageDetails';
import LayoutDetails from 'containers/ModalWindows/LayoutDetails';
import Notification from 'containers/ModalWindows/Notification';
import NotificationAccessError from 'containers/ModalWindows/NotificationAccessError';
import ProjectDetails from 'containers/ModalWindows/ProjectDetails';
import ProjectSource from 'containers/ModalWindows/ProjectSource';
import ReferenceCitationDetails from 'containers/ModalWindows/ReferenceCitationDetails';
import SaveGroupLayout from 'containers/ModalWindows/SaveGroupLayout';
import SaveReusableLayout from 'containers/ModalWindows/SaveReusableLayout';
import ScreenSettings from 'containers/ModalWindows/ScreenSettings';
import SSISource from 'containers/ModalWindows/SSISource';
import StoryCardDetails from 'containers/ModalWindows/StoryCardDetails';
import TextComponentDetails from 'containers/ModalWindows/TextComponentDetails';
import UploadProject from 'containers/ModalWindows/UploadProject';
import { Priority } from 'hooks/useClickOutside';
import { addEventListener, removeEventListener } from 'hooks/useClickOutside/listeners';
import { IModalManagerProps } from './models';
import styles from './styles.module.scss';

// [component, className]
type ModalWindowConfig = [React.ComponentClass<{ modalWindowId: string }>, string];

const ModalManagerConfig: Record<ModalType, ModalWindowConfig> = {
  [ModalType.ADD_IMAGE]: [AddImage, styles.AddImageWindow],
  [ModalType.ADD_REFERENCE_CITATION]: [AddReferenceCitation, styles.AddCitation],
  [ModalType.ADD_SCREEN]: [AddScreen, styles.UpdateScreen],
  [ModalType.CHOOSE_SCREEN_FORMAT]: [ChooseScreenFormat, styles.ChooseScreenFormat],
  [ModalType.COLOR_GRADIENT_PICKER]: [ColorGradientPicker, styles.ColorGradientPicker],
  [ModalType.CONFIRMATION]: [Confirmation, styles.ConfirmationWindow],
  [ModalType.DOCUMENT_DUPLICATES]: [DocumentDuplicates, styles.DocumentDuplicatesWindow],
  [ModalType.DOCUMENT_SAVE]: [DocumentSaving, styles.DocumentSavingWindow],
  [ModalType.EDIT_REUSABLE_LAYOUT]: [EditReusableLayout, styles.EditReusableLayout],
  [ModalType.EXPORT]: [Export, styles.ExportWindow],
  [ModalType.IMAGE_DETAILS]: [ImageDetails, styles.ImageDetails],
  [ModalType.LAYOUT_DETAILS]: [LayoutDetails, styles.LayoutDetails],
  [ModalType.NOTIFICATION]: [Notification, styles.NotificationWindow],
  [ModalType.NOTIFICATION_ACCESS_ERROR]: [NotificationAccessError, styles.NotificationAccessErrorWindow],
  [ModalType.PROJECT_DETAILS]: [ProjectDetails, styles.ProjectDetails],
  [ModalType.PROJECT_SOURCE]: [ProjectSource, styles.ProjectSourceWindow],
  [ModalType.REFERENCE_CITATION_DETAILS]: [ReferenceCitationDetails, styles.CitationDetails],
  [ModalType.SAVE_GROUP_LAYOUT]: [SaveGroupLayout, styles.SaveGroupLayout],
  [ModalType.SAVE_LAYOUT]: [SaveReusableLayout, styles.SaveReusableLayout],
  [ModalType.SCREEN_SETTINGS]: [ScreenSettings, styles.ScreenSettingsWindow],
  [ModalType.SSI_SOURCE]: [SSISource, styles.SSISourceWindow],
  [ModalType.STORY_CARD_DETAILS]: [StoryCardDetails, styles.StoryCardDetails],
  [ModalType.TEXT_COMPONENT_DETAILS]: [TextComponentDetails, styles.TextComponentDetails],
  [ModalType.UPLOAD_PROJECT]: [UploadProject, styles.UploadProjectWindow],
};

const StaticBackdropWindows: ModalType[] = [
  ModalType.CHOOSE_SCREEN_FORMAT,
  ModalType.PROJECT_SOURCE,
  ModalType.SCREEN_SETTINGS,
];

const ModalManager: React.StatelessComponent<IModalManagerProps> = (props) => {
  const { hideModal, modalWindows, activeModalIds } = props;

  React.useEffect(
    () => {
      const listener = _.noop;

      // when any modal window is opened we add stub listener
      // to freeze all other listeners that have lower priority than Priority.MODAL_WINDOW
      !activeModalIds.isEmpty() && addEventListener(Priority.MODAL_WINDOW, listener);

      return () => {
        !activeModalIds.isEmpty() && removeEventListener(Priority.MODAL_WINDOW, listener);
      };
    },
    [activeModalIds],
  );

  return (
    <>
      {
        activeModalIds.map((id) => {
          const modalWindow = modalWindows.get(id);
          const modalWindowType = modalWindow.get('type');
          const disableClickOutside = modalWindow.getIn(['options', 'disableClickOutside']);
          const topPriority = modalWindow.getIn(['options', 'topPriority']);
          const isStaticBackdrop = StaticBackdropWindows.includes(modalWindowType);
          const [ActiveModal, className] = ModalManagerConfig[modalWindowType];

          return (
            <Modal
              key={id}
              className={classnames(styles.ModalManager, { 'top-priority': topPriority })}
              dialogClassName={className}
              onHide={!disableClickOutside ? hideModal.bind(null, id) : _.noop}
              show={true}
              backdrop={isStaticBackdrop ? 'static' : true}
            >
              <ActiveModal modalWindowId={id}/>
            </Modal>
          );
        })
      }
    </>
  );
};

export default ModalManager;
