import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as Models from 'components/ModalWindows/Notification/models';
import NotificationAccessError from 'components/ModalWindows/NotificationAccessError';
import { downloadIssueReport } from 'containers/App/actions';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (state, props: Models.NotificationProps): ReturnType<typeof createStructuredSelector> => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps = {
  hideModal,
  downloadIssueReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationAccessError);
