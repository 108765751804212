import { LexicalNode, TextNode } from 'lexical';
import { FontPluginStyle } from '../Plugins/FontPlugin/style';

// replaces Models.FontFamily
export type CustomTextFontFamily = {
  fontFamily: string;
  characterStyleName?: string;
};

// replaces Models.BrandColor
export type CustomTextColor = {
  HEX: string;
  name?: string;
  tint?: number;
};

export class CustomTextNode extends TextNode {
  static getType(): string {
    return 'custom-text';
  }

  static colorToStyleArray(value: CustomTextColor | undefined): Record<string, string | null> {
    return {
      [FontPluginStyle.COLOR_HEX]: value?.HEX ?? null,
      [FontPluginStyle.COLOR_NAME]: value?.name ?? null,
      [FontPluginStyle.COLOR_TINT]: value?.tint === undefined ? null : value.tint.toString(),
    };
  }

  static fontFamilyToStyleArray(value: CustomTextFontFamily | undefined): Record<string, string | null> {
    return {
      [FontPluginStyle.FONT_FAMILY]: value?.fontFamily ?? null,
      [FontPluginStyle.CHARACTER_STYLE_NAME]: value?.characterStyleName ?? null,
    };
  }

  protected readStyleValues<T extends string[]>(
    ...keys: T
  ): { [key in T[number]]: string | undefined } {
    const styles = (this.__style ?? '').split(';');

    const entries = keys.map((key) => {
      const prefix = `${key}:`;
      const styleEntry = styles.findLast(entry => entry.trimStart().startsWith(prefix));
      if (!styleEntry) {
        return [key, undefined];
      }
      const value = styleEntry.trim().substring(prefix.length).trim();

      return [key, value];
    });

    return Object.fromEntries(entries);
  }

  protected writeStyleValues(values: Record<string, string | null>): void {
    const prefixes = Object.keys(values).map(key => `${key}:`);
    const clearedStyles = (this.__style ?? '').split(';')
      .filter(entry => !prefixes.some(prefix => entry.trimStart().startsWith(prefix)));
    const newStyles = Object.entries(values)
      .filter(([,value]) => !!value)
      .map(([key, value]) => `${key}: ${value as string}`);
    this.__style = [...clearedStyles, ...newStyles].join('; ');
  }

  static clone(node: CustomTextNode): CustomTextNode {
    return new CustomTextNode(node.__text, node.__key);
  }

  get __color(): CustomTextColor | undefined {
    const {
      [FontPluginStyle.COLOR_HEX]: HEX,
      [FontPluginStyle.COLOR_NAME]: name,
      [FontPluginStyle.COLOR_TINT]: tint,
    } = this.readStyleValues(
      FontPluginStyle.COLOR_HEX,
      FontPluginStyle.COLOR_NAME,
      FontPluginStyle.COLOR_TINT,
    );
    if (!HEX) {
      return undefined;
    }

    return { HEX, name, tint: tint ? Number(tint) : undefined };
  }

  set __color(value: CustomTextColor | undefined) {
    this.writeStyleValues(CustomTextNode.colorToStyleArray(value));
  }

  setColor(color: CustomTextColor | undefined): void {
    this.getWritable().__color = color;
  }

  getColor(): CustomTextColor | undefined {
    return this.getLatest().__color;
  }

  get __fontFamily(): CustomTextFontFamily | undefined {
    const {
      [FontPluginStyle.FONT_FAMILY]: fontFamily,
      [FontPluginStyle.CHARACTER_STYLE_NAME]: characterStyleName,
    } = this.readStyleValues(FontPluginStyle.FONT_FAMILY, FontPluginStyle.CHARACTER_STYLE_NAME);
    if (!fontFamily) {
      return undefined;
    }

    return { fontFamily, characterStyleName };
  }

  set __fontFamily(value: CustomTextFontFamily | undefined) {
    this.writeStyleValues(CustomTextNode.fontFamilyToStyleArray(value));
  }

  setFontFamily(value: CustomTextFontFamily | undefined): void {
    this.getWritable().__fontFamily = value;
  }

  getFontFamily(): CustomTextFontFamily | undefined {
    return this.getLatest().__fontFamily;
  }

}

export function $isCustomTextNode(node: LexicalNode | null | undefined): node is CustomTextNode {
  return node instanceof CustomTextNode;
}
