import axios, { AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios';
import _ from 'lodash';
import pako from 'pako';
import guid from 'uuid';

import { AxiosMethod, LambdaInvocationType, ResponseStatus } from 'const';
import { isMethod } from 'utils/isMethod';
import { uploadFile } from './api';
import { launchWebSocket } from './launchWebSocket';

const promiseMethods = [AxiosMethod.POST, AxiosMethod.GET, AxiosMethod.PUT];
// for the methods getUri, request, post, get, put, delete, etc. the config parameter is the last one
const configParameterIndexes: Record<AxiosMethod, number> = {
  [AxiosMethod.GET]: 1,
  [AxiosMethod.POST]: 2,
  [AxiosMethod.PUT]: 2,
};
const bodyParameterIndexes: Record<AxiosMethod, number | null> = {
  [AxiosMethod.GET]: null,
  [AxiosMethod.POST]: 1,
  [AxiosMethod.PUT]: 1,
};
const successfulWebSocketStatusCodes: Record<LambdaInvocationType, number> = {
  [LambdaInvocationType.REQUEST_RESPONSE]: 200,
  [LambdaInvocationType.EVENT]: 202,
  [LambdaInvocationType.DRY_RUN]: 204,
};

// this WS API used due to API Gateway integration timeout 29 sec (backend operations are could be too long)
// https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html
// backend lambda functions executes twice recursively
// on the first run lambda close API Gateway and invokes itself the second time
// on the second run lambda makes a call to REST API and sends the response back via WS
export const axiosWS = new Proxy(axios, {
  get(target, propKey, receiver) {
    if (!isMethod(target, propKey)) {
      return Reflect.get(target, propKey, receiver);
    }

    return new Proxy(target[propKey], {
      async apply(applyTarget: AxiosStatic[typeof propKey], thisArg: typeof target, args: Parameters<typeof applyTarget>) {
        if (!promiseMethods.includes(propKey as AxiosMethod)) {
          return Reflect.apply(applyTarget, thisArg, args);
        }

        // HACK: Do not use webSocket functionality if backend url does not belong to AWS APIGateway
        // TODO: Remove it when we get rid of EC2 backend instances
        if (!(args[0] as string).includes('.com')) {
          return Reflect.apply(applyTarget, thisArg, args);
        }

        // upload request body to S3 due to invocation payload lambda limit 6mb
        // https://docs.aws.amazon.com/lambda/latest/dg/gettingstarted-limits.html
        let bodyS3Key = '';

        const bodyArgIndex = bodyParameterIndexes[propKey as AxiosMethod];
        if (bodyArgIndex !== null) {
          const body = args[bodyArgIndex];
          if (body) {
            const bodyFile = new File(
              [pako.gzip(JSON.stringify(body))],
              `${guid()}.gzip`,
              { type: 'application/json' },
            );

            bodyS3Key = await uploadFile(bodyFile, 'application/json', {
              'Content-Encoding': 'gzip',
            });

            args[bodyArgIndex] = {};
          }
        }

        const { connectionId, responseHandler, endPoint } = await launchWebSocket();
        const parameterIndex = configParameterIndexes[propKey as AxiosMethod];
        const config: AxiosRequestConfig = args[parameterIndex] = args[parameterIndex] || {};

        config.headers = {
          ...(config.headers || {}),
          'merck-assembler-connection-id': connectionId,
          'merck-assembler-ws-endpoint': endPoint,
          ...(bodyS3Key && { 'merck-assembler-body-key': bodyS3Key }),
        };

        const { data }: AxiosResponse = await Reflect.apply(applyTarget, thisArg, args);

        // https://docs.aws.amazon.com/lambda/latest/dg/API_Invoke.html#API_Invoke_ResponseSyntax
        if (!_.includes(successfulWebSocketStatusCodes, data.data.StatusCode)) {
          return Promise.reject(data);
        }

        const responseUrl = await responseHandler();
        const response = await axios.get(responseUrl, { headers: { 'ignore-request-interceptor': true } });
        const { error, responseStatus } = response.data;

        if (responseStatus === ResponseStatus.FAILURE) {
          return Promise.reject(error);
        }

        return response;
      },
    });
  },
});
