import { TOGGLE_LINK_COMMAND, toggleLink } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical';
import { useEffect } from 'react';

export const LINK_COMMAND = createCommand<string | undefined>();

export type LinkProps = {
  link: string | undefined;
  linkApplicable: boolean;
};

type Props = {
  onChange: (values: LinkProps) => void;
};

export function LinkPropsPlugin(props: Props): null {
  const { onChange } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      LINK_COMMAND,
      (value) => {

        if (value === '') {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        } else {
          toggleLink(value ?? null, { target: '_blank' });
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  useEffect(() => {
    const readEditorStateAndNotifyChange = (): void => onChange({
      link: undefined,
      linkApplicable: true,
    });
    editor.getEditorState().read(readEditorStateAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readEditorStateAndNotifyChange);
    });
  }, [editor, onChange]);

  return null;
}
