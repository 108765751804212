import { put, take } from 'redux-saga/effects';
import guid from 'uuid';

import { ModalType } from 'const';
import { showModal } from 'containers/ModalManager/actions';
import { NotificationWindowOptions } from 'models';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';

export function* showNotification(options: NotificationWindowOptions): Generator {
  const modalWindowId = guid();

  yield put(showModal(ModalType.NOTIFICATION, options, modalWindowId));
  const { payload: { response } } = yield take(takeModalWindowResponse(modalWindowId));

  return response;
}

export function* showNotificationAccessError(options: NotificationWindowOptions): Generator {
  const modalWindowId = guid();

  yield put(showModal(ModalType.NOTIFICATION_ACCESS_ERROR, options, modalWindowId));
  const { payload: { response } } = yield take(takeModalWindowResponse(modalWindowId));

  return response;
}
