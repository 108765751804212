import React from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ExtendedAssemblerError } from 'models';
import { intlGet } from 'utils/intlGet';


function getCopiedMessage(asmolError: ExtendedAssemblerError): string {
  return `
  <strong>Veeva PromoMats experienced a problem<strong>
  ${asmolError.message}
  Global ID of the documents with which the issue occurred:
    1.${asmolError.documentId ?? ''}
  Technical details which are required for investigation (please don't delete):
  Request ID: ${asmolError.requestId ?? ''}
  Type: ${asmolError.type};
  TS: ${Date.now()};
  UserId: ${asmolError.userId ?? ''}.
  `;
}

const CopyErrorIcon: React.FunctionComponent<{ asmolError: ExtendedAssemblerError }> = ({ asmolError }) => {
  const [descriptionCopied, setDescriptionCopied] = React.useState(false);

  const onClickCopy = (): void => {
    setDescriptionCopied(true);
    void navigator.clipboard.writeText(
      getCopiedMessage(asmolError),
    );
    setTimeout(()=>{
      setDescriptionCopied(false);
    }, 3000);
  };


  if (descriptionCopied) {
    return (<>
      <Icon type={IconType.CHECK_MARK} color='grey' size='md' className='px-2'/>
      <span>Copied!</span>
    </>);
  }

  return (<><Icon type={IconType.CONTENT_COPY} color='success' size='md' className='px-2' onClick={onClickCopy}/>
    <span className='pl-2'>{intlGet('Notification.PromoMatsError.AccessError', 'CopyClick')}</span>
  </>);
};

export default CopyErrorIcon;
