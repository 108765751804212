import { showConfirmation } from './showConfirmation';
import { showMissingFonts } from './showMissingFonts';
import { showNotification, showNotificationAccessError } from './showNotification';
import { showNotUpdatedLayouts } from './showNotUpdatedLayouts';
import { success, warn } from './toasts';

export const Notifications = {
  // modal windows
  showConfirmation,
  showMissingFonts,
  showNotUpdatedLayouts,
  showNotification,
  showNotificationAccessError,

  // toasts
  success,
  warn,
};
