import _ from 'lodash';
import { lookup } from 'mime-types';

import { IconType } from 'components/Icon/constants';
import { ActionTypes as AppActionTypes } from 'containers/App/constants';
import { ActionTypes as ArtboardCellActionTypes } from 'containers/ArtboardCell/constants';
import { ActionTypes as ArtboardPreviewActionTypes } from 'containers/ArtboardPreview/constants';
import { ActionTypes as ArtboardsActionTypes } from 'containers/Artboards/constants';
import { ActionTypes as DocumentsActionTypes } from 'containers/Documents/constants';
import { ActionTypes as ProjectActionTypes } from 'containers/Project/constants';
import { ActionTypes as RelationsActionTypes } from 'containers/Relations/constants';
import { ActionTypes as RootDocumentActionTypes } from 'containers/RootDocument/constants';
import { intlGet } from 'utils/intlGet';
import { ComponentSize } from './Component';
import * as ErrorType from './ErrorType';
import * as Styles from './Styles';

export * from './AssetAlignment';
export * from './Axios';
export * from './AWS';
export * from './Box';
export * from './BackgroundImageType';
export * from './BrandStyle';
export * from './Char';
export * from './Color';
export * from './DocumentsKey';
export * from './Draft';
export * from './EntityType';
export * from './Environment';
export * from './Errors';
export * from './ExportType';
export * from './FontDecoration';
export * from './FontStyle';
export * from './Image';
export * from './Layer';
export * from './LineHeight';
export * from './Link';
export * from './PerformanceMethod';
export * from './Platform';
export * from './PreviewOptionName';
export * from './ProjectsConfig';
export * from './ProjectType';
export * from './Protocol';
export * from './ReusableLayoutChangeType';
export * from './ScreenFormat';
export * from './TextAlignment';
export * from './Component';
export * from './Layout';
export * from './Screens';
export * from './ZoomControl';
export { Styles };
export { ErrorType };

// Single constants
export const ASSEMBLER_HELP = 'https://contentcapabilities.com/';
export const IMPORT_TRANSLATION_PACKAGE_HELP = 'https://contentcapabilities.com/introduction-to-translation/how-to-use-the-xliff-translation-file/';
export const INSUFFICIENT_ACCESS_HELP = 'https://contentcapabilities.com/unable-to-upload-user-does-not-have-enough-privileges/';
export const MARCAPS_SUPPORT = 'https://marcapssupport.atlassian.net/servicedesk/customer/portal/7';

export const PROJECT_PDF_POSTFIX = '.AllScreens.pdf';
export const ARTBOARDS_JSON_NAME = 'artboards.json';
export const ISSUE_REPORT_NAME = 'issue-report.json';
export const MAX_NUMBER_OF_LOCALLY_SAVED_PROJECTS = 10;
export const UNICODE_HASH = 'unicode';

/* Allowed characters for Artboard name: A-Z a-z 0-9 _ space ! @ # $ % ^ ( ) + = ' , . { } [ ] -
 * Any other characters will be considered like forbidden ones
 */
export const forbiddenArtboardNameCharactersRegExp = /[^\w\ \!\@\#\$\%\^\(\)\+\=\'\,\.\{\}\[\]\-]/;
export const UUID_PATTERN = /[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/;

export const ARTBOARD_WIDTH = 660;
export const MAX_SCREEN_NAME_LENGTH = 34;
export const MAX_DOCUMENT_NAME_LENGTH = 100;
export const REFERENCES_MARKER = '{r}';
export const DEFAULT_SECTION = 'main_body';
export const DEFAULT_SPACER_HEIGHT = 50;
export const CUSTOM_FONT_FACES_ID = 'custom-font-faces';
export const CUSTOM_FONT_FACE_SEPARATOR = '-';
export const MAX_CELLS_COUNT = 50;
export const DEFAULT_SSI_SCALE = 0.3;

export const UNSTYLED_BLOCK_TYPE = 'unstyled';
export const TOOLBAR_ELEMENT_ID = 'toolbar';
export const TOOLTIP_ELEMENT_ID = 'tooltip';

export const MIN_HEIGHT_FOR_MD_LAYOUT_SPINNER = 85;
export const MIN_HEIGHT_FOR_SM_LAYOUT_SPINNER = 30;

export enum MinCellWidth {
  CTA = 40,
  IMAGE = 10,
  TEXT = 10,
}

export enum MinCellHeight {
  CTA = 10,
  IMAGE = 10,
  EMPTY_TEXT = 10,
}

// Enums
export enum AppRoute {
  IFRAME_PLACEHOLDER = 'iframe-placeholder',
  UNSUPPORTED_BROWSER = 'unsupported-browser',
  INTERSTITIAL = 'interstitial',
  NO_MATCH = 'no-match',
  PREVIEW = 'preview',
  PROJECT = 'project',
  // For blue-green deployment with different URL prefixes, e.g.
  // Blue: https://assembleronline.com/staging/project/1
  // Green: https://assembleronline.com/project/1
  BLUE_ENV_BASENAME = 'staging',
}

export enum TextStatusType {
  COPY = 'Copy',
  EDIT = 'Edit',
}

export enum DocumentsSource {
  FAVORITES = 'favorites',
  RELATED_DOCUMENTS = 'relatedDocuments',
  COMPONENTS = 'components',
  SHOPPING_CART = 'shoppingCart',
  PROJECT_FILE = 'projectFile',
  ONLINE = 'online',
  GROUP_LAYOUT = 'groupLayout',
}

export enum DocumentStatus {
  APPROVED_FOR_USE = 'Approved for Use',
  APPROVED_FOR_PRODUCTION = 'Approved for Production',
  APPROVED_FOR_DISTRIBUTION = 'Approved for Distribution',
  DRAFT = 'Draft',
}

export enum ModalType {
  ADD_IMAGE = 'AddImage',
  ADD_REFERENCE_CITATION = 'AddReferenceCitation',
  ADD_SCREEN = 'AddScreen',
  CHOOSE_SCREEN_FORMAT = 'ChooseScreenFormat',
  CONFIRMATION = 'Confirmation',
  DOCUMENT_DUPLICATES = 'DocumentDuplicates',
  DOCUMENT_SAVE = 'DocumentSaving',
  EDIT_REUSABLE_LAYOUT = 'EditReusableLayout',
  EXPORT = 'Export',
  IMAGE_DETAILS = 'ImageDetails',
  COLOR_GRADIENT_PICKER = 'ColorGradientPicker',
  LAYOUT_DETAILS = 'LayoutDetails',
  NOTIFICATION = 'Notification',
  NOTIFICATION_ACCESS_ERROR = 'NotificationAccessError',
  PROJECT_DETAILS = 'ProjectDetails',
  PROJECT_SOURCE = 'ProjectSource',
  REFERENCE_CITATION_DETAILS = 'ReferenceCitationDetails',
  SAVE_GROUP_LAYOUT = 'SaveGroupLayout',
  SAVE_LAYOUT = 'SaveReusableLayout',
  SCREEN_SETTINGS = 'ScreenSettings',
  SSI_SOURCE = 'SSISource',
  STORY_CARD_DETAILS = 'StoryCardDetails',
  TEXT_COMPONENT_DETAILS = 'TextComponentDetails',
  UPLOAD_PROJECT = 'UploadProject',
}

export enum AddCellDirection {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum SplitCellDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum ResponseStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export enum DocumentType {
  MATERIAL = 'Material',
  COMPONENT = 'Component',
}

export enum DocumentSubtype {
  ASSEMBLER = 'Assembler',
  CONTENT_UNIT = 'Content Unit',
  IMAGE = 'Image',
  MAGIC_FORM = 'Variable Content Form',
  MASTER_SURFACE = 'Master Surface',
  STORY_CARD = 'Story Card',
  TEXT_COLLECTION = 'Text Collection',
}

export enum DocumentClassification {
  PROJECT_FILE = 'Project File',
  BRAND_DEFINITION = 'Brand Definition',
  SURFACE_DEFINITION = 'Surface Definition',
  SURFACE_TEMPLATE = 'Surface Template',
  SURFACE_LAYOUT = 'Surface Layout',
  REFERENCE_CITATION = 'Reference Citation',
  PHOTOGRAPH = 'Photograph',
  XSD = 'XSD',
  UNCLASSIFIED = 'Unclassified',
}

export enum AssetFilter {
  IMAGES = 'Images',
  REFERENCES = 'References',
  LAYOUTS = 'ReusableLayouts',
  STORY_CARDS = 'StoryCards',
  MODULE_BUNDLES = 'ModuleBundles',
  TEXT = 'Text',
  TEXT_COLLECTIONS = 'TextCollections',
  GROUP_LAYOUTS = 'GroupLayouts',
}

export enum DragSourceType {
  ARTBOARD_GROUP_LAYOUT = 'ARTBOARD_GROUP_LAYOUT',
  ARTBOARD_LAYOUT = 'ARTBOARD_LAYOUT',
  ARTBOARD_REUSABLE_LAYOUT = 'ARTBOARD_REUSABLE_LAYOUT',
  ARTBOARD_SSI = 'ARTBOARD_SSI',
  ASSET = 'ASSET',
  GROUP_LAYOUT = 'GROUP_LAYOUT',
  GROUP_LAYOUT_REUSABLE_LAYOUT = 'GROUP_LAYOUT_REUSABLE_LAYOUT',
  LAYOUT = 'LAYOUT',
  REUSABLE_LAYOUT = 'REUSABLE_LAYOUT',
  SCREEN = 'SCREEN',
  SSI = 'SSI',
  STORY_CARD = 'STORY_CARD',
}

export enum ProcessType {
  DOCUMENT_UPLOADING = 'DOCUMENT_UPLOADING',
  DUPLICATES_CHECKING = 'DUPLICATES_CHECKING',
  EXPORTING_FILES = 'EXPORTING_FILES',
  GET_PROJECT_DATA = 'GET_PROJECT_DATA',
  IMPORT_TRANSLATION_PACKAGE = 'IMPORT_TRANSLATION_PACKAGE',
  REFRESH = 'REFRESH',
  SAVE_GROUPED_REUSABLE_LAYOUT = 'SAVE_GROUPED_REUSABLE_LAYOUT',
  SAVE_REUSABLE_LAYOUT = 'SAVE_REUSABLE_LAYOUT',
  SAVE_LAYOUT_WITHOUT_MESSAGE = 'SAVE_LAYOUT_WITHOUT_MESSAGE',
  UPLOAD_TO_PROMOMATS = 'UPLOAD_TO_PROMOMATS',
}

export enum DocumentRefreshStatus {
  ADDED_IN_PROMOMATS = 'ADDED_IN_PROMOMATS',
  UPDATED_IN_PROMOMATS = 'UPDATED_IN_PROMOMATS',
}

export enum UploadProjectType {
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  DRAFT = 'DRAFT',
}

export enum DocumentSavingStatus {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  CANCEL = 'CANCEL',
}

export enum SourceProjectType {
  LOCAL = 'LOCAL',
  PROMOMATS = 'PROMOMATS',
}

export enum ProjectPanelTab {
  ASSETS = 'ASSETS',
  ELEMENTS = 'ELEMENTS',
}

export enum ProjectPanelSection {
  IMAGES = 'IMAGES',
  REFERENCE_CITATIONS = 'REFERENCE_CITATIONS',
  REUSABLE_LAYOUTS = 'REUSABLE_LAYOUTS',
  TEXTS = 'TEXTS',

  // TODO: should be adjusted with rest sections
  // if we need to handle them via redux
}

// Images renditions
// TODO: replace with more readable one
export enum RenditionType {
  LOW = 'lo__c',
  HIGH = 'high__c',
  VIEWABLE_RENDITION = 'viewable_rendition__v',
  CUSTOM_THUMBNAIL = 'portal_rendition__v',
}

export enum ResolutionType {
  LOW = 'low',
  HIGH = 'high',
  SOURCE = 'source',
}

export enum ImageExtension {
  AI = '.AI',
  EPS = '.EPS',
  JPG = '.JPG',
  JPEG = '.JPEG',
  PNG = '.PNG',
  PSD = '.PSD',
}

export enum ImageInputStatus {
  EMPTY = 'EMPTY',
  INITIAL = 'INITIAL',
  ADDED = 'ADDED',
  CHANGED = 'CHANGED',
}

// Keyboard Key Values
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
export enum KeyboardKey {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ENTER = 'Enter',
  PAGE_UP = 'PageUp',
  PAGE_DOWN = 'PageDown',
}

export enum ToastType {
  SUCCESS = 'SUCCESS',
  // TODO: get rid of ERROR toasts
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum AssetsCollectionNavItem {
  IMAGES = 'IMAGES',
  REFERENCE_CITATIONS = 'REFERENCE_CITATIONS',
  LAYOUTS = 'LAYOUTS',
  TEXTS = 'TEXTS',
}

export enum SSIPosition {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum ProjectVersion {
  V1 = '1',
  V2 = '2',
  V3 = '3',
  V4 = '4',
}

export enum ReusableLayoutVersion {
  V1 = '1',
  V2 = '2',
  V3 = '3',
}

export const ReusableLayoutVersions = [
  ReusableLayoutVersion.V1,
  ReusableLayoutVersion.V2,
  ReusableLayoutVersion.V3,
];

// the latest version
export const LATEST_REUSABLE_LAYOUT_VERSION = ReusableLayoutVersions[ReusableLayoutVersions.length - 1];

export enum ResizerDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

// Helpers
export const AutoSaveExceptions = new Set([
  ..._.values(ArtboardPreviewActionTypes),
  AppActionTypes.ADD_ACTIVE_LAYOUT_ID,
  AppActionTypes.SAVE_PROJECT_LOCALLY,
  AppActionTypes.REMOVE_ACTIVE_LAYOUT_ID,
  AppActionTypes.SET_IS_RESIZING_LAYOUT,
  AppActionTypes.SET_MOVEABLE_LAYOUT_ID,
  ArtboardsActionTypes.ARTBOARD_MOUNTED,
  ArtboardsActionTypes.ARTBOARD_READY,
  ArtboardsActionTypes.ARTBOARD_WILL_BE_MOUNTED,
  DocumentsActionTypes.UPDATE_INTERNAL_IMAGE_INFO,
  DocumentsActionTypes.UPDATE_INTERNAL_IMAGE_INFO_REQUEST,
  DocumentsActionTypes.UPDATE_LAYOUT_DOCUMENT_THUMBNAIL_URL,
  DocumentsActionTypes.UPDATE_STORY_CARD_IMAGE_INFO,
  DocumentsActionTypes.UPDATE_STORY_CARD_LAYOUT_THUMBNAIL,
  ProjectActionTypes.END_LOCAL_SAVE,
  ProjectActionTypes.SET_AUTO_SAVE_STATE,
  ProjectActionTypes.SET_LAST_LOCAL_SAVE_TIME,
  ProjectActionTypes.START_LOCAL_SAVE,
  RelationsActionTypes.UPDATE_LAYERED_RELATIONS_SILENTLY,
  RelationsActionTypes.UPDATE_LAYOUT_ASSETS_SILENTLY,
  RootDocumentActionTypes.SET_PROJECT_DETAILS,
]);

export const UndoRedoActions = new Set([
  ArtboardCellActionTypes.DROP_ASSET,
  RelationsActionTypes.ADD_RELATIONS,
  RelationsActionTypes.DELETE_COMPONENTS,
  RelationsActionTypes.DELETE_RELATIONS,
  RelationsActionTypes.MERGE_RELATIONS,
  RelationsActionTypes.REPLACE_DOCUMENT_ID_IN_RELATIONS,
  RelationsActionTypes.SET_RELATIONS,
  RelationsActionTypes.UPDATE_RELATION,
  RelationsActionTypes.UPDATE_RELATIONS,
  DocumentsActionTypes.ADD_DOCUMENTS,
  DocumentsActionTypes.ADD_IMAGE,
  DocumentsActionTypes.ADD_REFERENCE_CITATION,
  DocumentsActionTypes.DELETE_COMPONENT,
  DocumentsActionTypes.DELETE_DOCUMENTS,
  DocumentsActionTypes.DOWNLOAD_IMAGES,
  DocumentsActionTypes.GET_ASSETS_COLLECTION_DATA,
  DocumentsActionTypes.GET_CITATION_INFO,
  DocumentsActionTypes.GET_IMAGES_INFO,
  DocumentsActionTypes.GET_LAYOUTS_THUMBNAILS,
  DocumentsActionTypes.GET_STORY_CARDS_PREVIEWS,
  DocumentsActionTypes.GET_STORY_CARD_IMAGES_INFO,
  DocumentsActionTypes.GET_STORY_CARD_LAYOUT_THUMBNAILS,
  DocumentsActionTypes.MERGE_DOCUMENTS,
  DocumentsActionTypes.REMOVE_ANCHOR,
  DocumentsActionTypes.SET_ASSETS_COLLECTION_DATA,
  DocumentsActionTypes.SET_DOCUMENT,
  DocumentsActionTypes.SET_DOCUMENTS,
  DocumentsActionTypes.UPDATE_DOCUMENT_SOURCE,
  DocumentsActionTypes.UPDATE_IMAGE,
  DocumentsActionTypes.UPDATE_INTERNAL_IMAGE_INFO,
  DocumentsActionTypes.UPDATE_INTERNAL_IMAGE_INFO_REQUEST,
  DocumentsActionTypes.UPDATE_LAYOUT_DOCUMENT_THUMBNAIL_URL,
  DocumentsActionTypes.UPDATE_REFERENCE_REQUEST,
  DocumentsActionTypes.UPDATE_STORY_CARD_IMAGE_INFO,
  DocumentsActionTypes.UPDATE_STORY_CARD_LAYOUT_THUMBNAIL,
]);

export const HandleReusableLayoutsEditingExceptions = new Set([
  ...AutoSaveExceptions,
]);

export const AssetsCollectionNavIcons: Record<AssetsCollectionNavItem, IconType> = {
  [AssetsCollectionNavItem.IMAGES]: IconType.IMAGE,
  [AssetsCollectionNavItem.REFERENCE_CITATIONS]: IconType.REFERENCE_CITATION,
  [AssetsCollectionNavItem.LAYOUTS]: IconType.LAYOUT,
  [AssetsCollectionNavItem.TEXTS]: IconType.TEXT,
};

export const AssetsCollectionTooltipText: Record<AssetsCollectionNavItem, () => string> = {
  [AssetsCollectionNavItem.IMAGES]: () => intlGet('EditorToolbar.Tooltip', 'Images'),
  [AssetsCollectionNavItem.REFERENCE_CITATIONS]: () => intlGet('EditorToolbar.Tooltip', 'ReferenceCitations'),
  [AssetsCollectionNavItem.LAYOUTS]: () => intlGet('EditorToolbar.Tooltip', 'Layouts'),
  [AssetsCollectionNavItem.TEXTS]: () => intlGet('EditorToolbar.Tooltip', 'TextComponents'),
};

export const ModuleBundleNavItems: AssetsCollectionNavItem[] = [
  AssetsCollectionNavItem.LAYOUTS,
];

export const StoryCardNavItems: AssetsCollectionNavItem[] = [
  AssetsCollectionNavItem.TEXTS,
  AssetsCollectionNavItem.IMAGES,
  AssetsCollectionNavItem.REFERENCE_CITATIONS,
  AssetsCollectionNavItem.LAYOUTS,
];

export const TextCollectionNavItems: AssetsCollectionNavItem[] = [
  AssetsCollectionNavItem.TEXTS,
  AssetsCollectionNavItem.REFERENCE_CITATIONS,
  AssetsCollectionNavItem.LAYOUTS,
];

export const OrderedDocumentSources = [
  DocumentsSource.FAVORITES,
  DocumentsSource.RELATED_DOCUMENTS,
  DocumentsSource.SHOPPING_CART,
];

export const OrderedAssetFilters = [
  AssetFilter.STORY_CARDS,
  AssetFilter.TEXT,
  AssetFilter.TEXT_COLLECTIONS,
  AssetFilter.REFERENCES,
  AssetFilter.GROUP_LAYOUTS,
  AssetFilter.LAYOUTS,
  AssetFilter.IMAGES,
];

// size in pixels
export const CallToActionSizes = {
  [ComponentSize.SMALL]: {
    width: 150,
    height: 45,
  },
  [ComponentSize.MEDIUM]: {
    width: 200,
    height: 50,
  },
  [ComponentSize.LARGE]: {
    width: 260,
    height: 60,
  },
} as Record<ComponentSize, { height: number; width: number }>;

// size in pixels
export const SpacerSizes = {
  small: {
    height: 50,
  },
  medium: {
    height: 75,
  },
  large: {
    height: 100,
  },
} as Record<ComponentSize, { height: number }>;

export const ALLOWED_IMAGE_MIME_TYPES = _(ImageExtension).values().map(lookup).uniq().join(', ');
